<template>
    <v-card class="teros-elevation" :color="tableColor">
      <v-card-title class="px-5 pb-0 v-title-cadastro">
        <span class="h5 font-weight-bold">Projeto: <span class="">{{ projeto.titulo }}</span></span>
      </v-card-title>
      <MasterDetail
        ref="masterDetail"
        :hasExportCSV="false"
        :hasExportXLSX="false"
        :opts="opts"
        :cols="cols"
        :canEdit="true"
        :canDelete="true"
        :customResource="customResource"
        :hasMonthlyFilter="true">

        <DropdownCaptacao>
        </DropdownCaptacao>

      </MasterDetail>
    </v-card>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';
//import FormModal from '@/components/form-modal.vue';

export default {
    // props: {},
    components: {
        MasterDetail,
        DropdownCaptacao: () => import('@/components/dropdown-captacao.vue'),
        // FormModal,
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
            projeto: {
              titulo: '',
            },
            opts: {
              colaboradores: [],
            }
         }
     },
    computed: {
      ...mapGetters(['clientId']),
      actionBarButtons() {
        const actionBarButtons = [];
        // const that = this;
        actionBarButtons.push({
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: true,
          action(){
            alert('Em desenvolvimento...')
            // this.$route.push({ name: "projetos-captacao-form", query: { clientId: that.clientId }});
          }
        });

        return actionBarButtons.filter(({ show }) => show);
      },
      projetoId() {
        return this.$route.query.projetoId;
      },
      resourceUrl() {
        return this.apiResource(`/v1/captacao/${this.clientId}/timesheets/${this.projetoId}`);
      },
      customResource() {
        const resource = this.resourceUrl;
        return {
          ...resource,
          get(args) {
            return resource.get({ ...args }).then((result) => {
              return result;
            });
          },
          save(data) {
            const timesheets = {
              id: data.id || null,
              funcionario_id: data.funcionario_id,
              percentual: data.percentual,
              competencia: data.competencia,
            }
            return resource.save(timesheets, timesheets.id).then((result) => {
              return result;
            })
          }
        }
      },
      cols() {
        return [
          {
            key: "id",
            name: "Código",
            hideInform: true,
            hideInTable: true,
          },
          {
            key: "matricula",
            name: "Matricula",
            hideInform: true,
          },
          {
            key: "funcionario_id",
            name: "Matricula",
            type: this.$fieldTypes.AUTOCOMPLETE,
            rel: { to: "colaboradores", key: "id", name: "matricula" },
            colSize: 6,
            editable: false,
            hideInTable: true,
          },
          {
            key: "funcionario_id",
            name: "Colaborador",
            type: this.$fieldTypes.AUTOCOMPLETE,
            rules: [{ rule: "required" }],
            rel: { to: "colaboradores", key: "id", name: "nome" },
            colSize: 6,
            filterable: true,
            hideInTable: true,
          },
          {
            key: "nome",
            name: "Colaborador",
            type: this.$fieldTypes.AUTOCOMPLETE,
            rules: [{ rule: "required" }],
            colSize: 6,
            filterable: true,
            hideInform: true,
          },
          {
            key: "competencia",
            name: "Competência",
            type: this.$fieldTypes.MONTH,
            colSize: 6,
            filterable: true,
          },
          {
            key: "percentual",
            name: "Dedicação (%)",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            colSize: 6,
            filterable: true,
          },
          {
            key: "totalCustoFunc",
            name: "Total custo func.",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            hideInform: true,
            colSize: 6,
          },
          {
            key: "custoPED",
            name: "Custo P&D.",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            hideInform: true,
            colSize: 6,
          },
          {
            key: "custoINSS",
            name: "Custo INSS",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            hideInform: true,
            colSize: 6,
          },
          {
            key: "encargoINSS",
            name: "Encargo INSS",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            hideInform: true,
            colSize: 6,
          },
          {
            key: "custoFGTS",
            name: "Custo FGTS",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            hideInform: true,
            colSize: 6,
          },
          {
            key: "encargoFGTS",
            name: "Encargo FGTS",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            hideInform: true,
            colSize: 6,
          },
        ]
      }
    },
    // filters: {},
    created() {
      this.getProjetoSelecionado();
      this.getColaboradores();
    },
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
      getProjetoSelecionado(){
        return this.apiResource(`/v1/captacao/projetos/${this.clientId}/${this.projetoId}`).get().then((result) => {
          return this.projeto = result;
        });
      },
      getColaboradores(){
        return this.apiResource(`/v1/rh/${this.clientId}/colaboradores`).get().then((result) => {
          return this.opts.colaboradores = result.data || [];
        });
      }
     }
}
</script>

<style>

</style>
